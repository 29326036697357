/*
 * @Author: 关
 * @Date: 2022-08-29 15:03:00
 * @LastEditTime: 2022-08-29 15:03:00
 * @LastEditors: 关
 * @Description: 计算属性集合
 * @FilePath: \tp-vue2\src\store\getters.js
 */

const getters = {
  website: state => state.common.website,
  isRefresh: state => state.common.isRefresh,
  access_token: state => state.user.access_token,
  refresh_token: state => state.user.refresh_token,
  userInfo: state => state.user.userInfo,
}
export default getters
