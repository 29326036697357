/**
 * 存储localStorage
 */
export const setStorage = (key,value) => {
    window.localStorage.setItem(key, typeof value=='object'?JSON.stringify(value):value);
}
/**
 * 获取localStorage
 */
export const getStorage = (key) => {
    const value=window.localStorage.getItem(key);
    try {
        return JSON.parse(value);
    } catch (e) {
        return value;
    }
}
/**
 * 删除localStorage
 */
export const removeStorage = (key) => {
    window.localStorage.removeItem(key);
}

/**
 * 获取全部localStorage
 */
export const getAllStorage = () => {
    const list = []
    for (let i = 0; i <= window.localStorage.length; i++) {
        const key=window.localStorage.key(i);
        const value=getStorage(key);
        list.push({key,value});
    }
    return list;
}

/**
 * 清空全部localStorage
 */
export const clearStorage = () => {
    window.localStorage.clear();
}
