<template>
  <div id="app">
    <router-view />

    <!--联系我们固定-->

    <div class="contact_fixed">
      <div
        :class="'contact_fixed_box_normal ' + item.id"
        v-for="(item, index) in contactList"
        @mouseenter="spread(index)"
        @mouseleave="shrink(index)"
        id="contact"
      >
        <img :src="item.imgUrl" />
        <div v-show="activeIndex == index" class="desc_box">
          <div class="contact_title">{{ item.title }}</div>
          <div class="contact_desc">{{ item.desc }}</div>
        </div>
      </div>
      <div class="contact_fixed_box_normal top" @click="toTop">
        <img src="./assets/homepage/top_icon.png" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeIndex: -1,
      contactList: [
        {
          id: "phone",
          title: "联系电话",
          desc: "400-780-0159",
          imgUrl: require("@/assets/homepage/phone_icon.png"),
        },
        {
          id: "website",
          title: "公司官网",
          desc: "www.skygovern.com",
          imgUrl: require("@/assets/homepage/website_icon.png"),
        },
        {
          id: "email",
          title: "电子邮箱",
          desc: "tianhua@skygovern.com",
          imgUrl: require("@/assets/homepage/email_icon.png"),
        },
        {
          id: "address",
          title: "公司地址",
          desc: "金华市金东区多湖街道光南路广润翰城北门4幢120室",
          imgUrl: require("@/assets/homepage/address_icon.png"),
        },
        // {
        //   title: "联系电话",
        //   desc: "400-780-0159",
        //   imgUrl: require("@/assets/homepage/top_icon.png"),
        // },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    spread(index) {
      this.activeIndex = index;
    },
    shrink(index) {
      this.activeIndex = -1;
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: "微软雅黑", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 1200px;
  background-color: #fff;
  overflow-x: hidden;
}
.contact_fixed {
  position: fixed;
  top: 50%;
  right: 10px;
  height: 310px;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  justify-content: space-between;
  .top {
    cursor: pointer;
  }
  // .contact_fixed_box_active {
  //   // width: 200px;
  //   height: 50px;
  //   line-height: 20px;
  //   border-radius: 10px 10px 10px 10px;
  //   background-color: rgba(255, 255, 255, 1);
  //   text-align: center;
  //   box-shadow: 0px 0px 5px 0px rgba(52, 111, 255, 0.2);
  //   border: 1px solid rgba(52, 111, 255, 0.1);
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   justify-content: center;
  //   img {
  //     width: 40px;
  //     height: 40px;
  //     margin-right: 5px;
  //   }
  //   .desc_box {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: space-between;
  //     height: 40px;
  //     overflow: hidden;
  //     margin-right: 10px;
  //   }
  // }
  .contact_fixed_box_normal {
    // width: 50px;
    height: 50px;
    line-height: 20px;
    border-radius: 10px 10px 10px 10px;
    background-color: rgba(255, 255, 255, 1);
    text-align: center;
    box-shadow: 0px 0px 5px 0px rgba(52, 111, 255, 0.2);
    border: 1px solid rgba(52, 111, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    transition: all 0.3s;

    img {
      width: 40px;
      height: 40px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .desc_box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 40px;
      margin-right: 10px;
    }
  }
  .contact_title {
    color: rgba(51, 51, 51, 1);
    font-size: 14px;
    text-align: left;
    font-weight: bold;
    overflow: hidden;
  }
  .contact_desc {
    color: rgba(102, 102, 102, 1);
    font-size: 14px;
    text-align: left;
    overflow: hidden;
  }
}
// #contact {
//   transition: width 0.3s;
// }
.phone {
  &:hover {
    min-width: 150px;
    transition: all 0.3s;
  }
}
.website {
  &:hover {
    min-width: 190px;
    transition: all 0.3s;
  }
}
.email {
  &:hover {
    min-width: 220px;
    transition: all 0.3s;
  }
}
.address {
  &:hover {
    min-width: 390px;
    transition: all 0.3s;
  }
}
</style>
