/*
 * @Author: 关
 * @Date: 2022-08-29 15:03:00
 * @LastEditTime: 2022-08-29 15:03:00
 * @LastEditors: 关
 * @Description: 路由配置
 * @FilePath: \tp-vue2\src\router\index.js
 */

import Vue from "vue";
import VueRouter from "vue-router";
// import PageRoutes from './page'
// import ViewsRoutes from './views'

Vue.use(VueRouter);
// 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const Default = () => import("@/page/index/index.vue");
const Home = () => import("@/views/business-a/page-a/index.vue");
const Heqi = () => import("@/views/introduce/heqi/index.vue");
const Community = () => import("@/views/introduce/community/index.vue");
const Park = () => import("@/views/introduce/park/index.vue");
const Wateraffairs = () => import("@/views/introduce/WaterAffairs/index.vue");
// const AboutusIndex = () => import('@/views/about/aboutus/index.vue')
const aboutUs = () => import("@/views/about/aboutus/index.vue");
const product = () => import("@/views/product/index.vue");
const productDetail = () => import("@/views/product/productDetail.vue");
const casus = () => import("@/views/casus/index.vue");
const casusDetail = () => import("@/views/casus/casusDetail.vue");
const contact = () => import("@/views/contact/index.vue");
const news = () => import("@/views/news/index.vue");
// const newsDetail = () => import("@/views/news/newsDetail.vue");

// 所有路由的集合
const routes = [
  /*...PageRoutes,
    ...LayoutViewsRoutes*/
  {
    path: "/",
    name: "Default",
    component: Default,
    redirect: "/",
    children: [
      { path: "/", name: "BusinessAPageA", component: Home },
      {
        path: "introduce/heqi",
        name: "heqiIndex",
        component: Heqi,
        props: true,
      },
      {
        path: "introduce/community",
        name: "communityIndex",
        component: Community,
      },
      { path: "introduce/park", name: "parkIndex", component: Park },
      {
        path: "introduce/wateraffairs",
        name: "wateraffairsIndex",
        component: Wateraffairs,
      },
      { path: "about/aboutus", name: "aboutUs", component: aboutUs },
      { path: "product", name: "product", component: product },
      {
        path: "product/detail/:title/:desc",
        name: "productDetail",
        component: productDetail,
      },
      { path: "casus/:category", name: "casus", component: casus },
      {
        path: "casus/detail/:categoryName/:systemName",
        name: "casusDetail",
        component: casusDetail,
      },
      { path: "contact", name: "contact", component: contact },
      { path: "news", name: "news", component: news },
      // {
      //   path: "news/detail/:id",
      //   name: "newsDetail",
      //   component: newsDetail,
      // },
    ],
  },
];
const router = new VueRouter({
  // base: process.env.BASE_URL,
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
